.labelTableCellContainer {
  height: 24px;
  min-width: 24px;
  line-height: 0;
  cursor: default;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 700;
  white-space: nowrap;
  padding: 0 6px;
  border-radius: 6px;
  font-family: inherit;
}
