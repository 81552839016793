.uploadedFileContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 8px 8px 12px;
  border-radius: 8px;
  border: 1px solid;

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .image {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    align-items: center;
    position: relative;
    display: inline-flex;
    justify-content: center;
    border-radius: 10px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .fileInformation {
    flex: 1 1 auto;
    min-width: 0;
    margin: 0;

    .filename {
      font-family: inherit;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.57143;
      margin: 0;
    }

    .filesize {
      margin: 0;
      font-family: inherit;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.5;
    }
  }

  .closeIcon {
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      opacity: 0.72;
    }
  }
}
