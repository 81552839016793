@import '../../../styles/mixins';

.pdfPreviewDialog {
  :global(.MuiDialog-paper) {
    max-width: 900px;
    width: 100%;
  }

  .dialogTitle {
    margin: 0;
    font-weight: 600;
    font-size: 1.0625rem;
    line-height: 1.55556;
    flex: 0 0 auto;
    padding: 24px 24px 16px;
    font-family: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include bp(s) {
      font-size: 1.125rem;
    }
  }

  .dialogSubtitle {
    margin: 0;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.5;
    flex: 0 0 auto;
    padding: 0 24px 16px;
    font-family: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include bp(s) {
      font-size: 0.875rem;
    }
  }

  .dialogContent {
    flex: 1 1 auto;
    overflow-y : auto;
    padding: 0 24px;
    font-family: inherit;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.57143;
    max-width: 900px;
    width: 100%;

    .passwordContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-width: 300px;
      margin: 6px auto;
    }

    .pdfPreview {
      width: 100%;

      canvas {
        margin-left: auto;
        margin-right: auto;
      }

      .page {
        border-top: 2px solid;
        border-bottom: 2px solid;
        border-left: 2px solid;
        border-right: 2px solid;
        margin-bottom: 18px;
      }
    }

    .readButton {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }

    .loader {
      height: 300px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .dialogActions {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    flex: 0 0 auto;
    padding: 24px;
  }
}
