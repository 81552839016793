.workLogContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;

  .avatar {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    user-select: none;
    width: 40px;
    height: 40px;
    border: 2px solid;

    img {
      width: 100%;
      height: 100%;
      text-align: center;
      object-fit: cover;
      color: transparent;
      text-indent: 10000px;
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-grow: 1;

    .nameContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding-right: 40px;

      .name {
        margin: 0;
        font-family: inherit;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.57143;
      }

      .closeIcon {
        position: absolute;
        top: 4px;
        right: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        font-family: inherit;
        text-align: center;
        font-size: 1.125rem;
        outline: 0;
        border: none;
        margin: 0;
        text-decoration: none;
        flex: 0 0 auto;
        border-radius: 50%;
        overflow: visible;
        transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
        padding: 2.8px;

        &:hover {
          opacity: 0.8;
        }

        svg {
          width: 12px;
          height: 12px;
        }
      }

      .date {
        margin: 0;
        font-family: inherit;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.5;
      }

      .content {
        margin: 0;
        font-family: inherit;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.57143;
      }

      .timeSpent {
        margin: 0;
        font-family: inherit;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.5;
      }
    }
  }
}
