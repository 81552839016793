@import '../../../styles/mixins';

.sliderWidgetContainer {
  background-image: none;
  position: relative;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12);
  z-index: 0;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  border-radius: 16px;

  .dotsContainer {
    height: 18px;
    z-index: 9;
    display: flex;
    align-items: center;
    gap: 8px;
    top: 16px;
    left: 16px;
    position: absolute;
    margin: 0;
    padding: 0;

    .dotContainer {
      display: inline-flex;
      color: inherit;

      .dot {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        background-color: transparent;
        outline: 0;
        border: 0;
        margin: 0;
        border-radius: 100%;
        padding: 0;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        color: inherit;
        width: 8px;
        height: 8px;
        opacity: 0.24;

        &.isActive {
          opacity: 1;
        }
      }
    }
  }

  .carouselContainer {
    margin: auto;
    max-width: 100%;
    overflow: hidden;
    position: relative;

    .carouselInner {
      display: flex;
      backface-visibility: hidden;
      touch-action: pan-y pinch-zoom;
      margin-left: 0;

      .slideContainer {
        display: block;
        position: relative;
        min-width: 0;
        flex: 0 0 100%;

        .slideInner {
          width: 100%;
          position: relative;
          height: 288px;

          @include bp(1536px) {
            height: 320px;
          }

          .slideInfoContainer {
            padding: 24px;
            gap: 8px;
            width: 100%;
            bottom: 0;
            z-index: 9;
            display: flex;
            position: absolute;
            flex-direction: column;

            .tag {
              font-family: inherit;
              margin: 0;
              font-weight: 700;
              font-size: 0.75rem;
              line-height: 1.5;
              text-transform: uppercase;
            }

            .title {
              font-family: inherit;
              margin: 0;
              font-weight: 700;
              font-size: 1.125rem;
              line-height: 1.5;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-decoration: none;
              color: inherit;

              @include bp(s) {
                font-size: 1.1875rem;
              }
            }

            .subtitle {
              font-family: inherit;
              margin: 0;
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1.57143;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .imageContainer {
            max-width: 100%;
            overflow: hidden;
            position: relative;
            display: inline-block;
            vertical-align: bottom;
            width: 100%;
            height: 100%;

            .overlay {
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: inherit;
              border-radius: inherit;
              z-index: 1;
              position: absolute;
              background-image: linear-gradient(to bottom, transparent 0%, #000000 75%);
            }

            .image {
              opacity: 1;
              transition: opacity 150ms, filter 300ms;
              width: 100%;
              height: 100%;
              display: inherit;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}