@import '../../../styles/mixins';

.tableWidgetContainer {
  position: relative;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 16px;
  overflow: hidden;
  z-index: 0;

  .titleContainer {
    display: flex;
    align-items: center;
    padding: 24px 24px 0;
    margin-bottom: 24px;

    .titleInner {
      flex: 1 1 auto;

      .title {
        margin: 0;
        font-family: inherit;
        font-weight: 600;
        font-size: 1.0625rem;
        line-height: 1.55556;

        @include bp(s) {
          font-size: 1.125rem;
        }
      }
    }
  }

  > div {
    border-radius: 0;
  }
}