@import '../../styles/mixins';

.drawerContainer {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  backdrop-filter: blur(20px);
  background-image: url('../../../assets/popover-blur-blue.png'),
    url('../../../assets/popover-blur-red.png');
  background-repeat: no-repeat, no-repeat;
  background-size: 50%, 50%;
  box-shadow: -40px 40px 80px -8px rgba(145, 158, 171, 0.24);
  flex: 1 0 auto;
  outline: 0;
  background-position: right top, left bottom;
  width: 100%;

  @include bp(s) {
    width: 480px;
  }

  .upperContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 8px 20px 20px;
    border-bottom: solid 1px;
  }
}
