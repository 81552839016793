.editableCellContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  z-index: 9999;

  .input {
    width: 100%;

    input {
      font-size: 0.875rem;
      line-height: 1.57143;
      font-weight: 500;
    }
  }
}
