.dynamicInputContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  .label {
    font-family: inherit;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.57143;
    margin: 0 0 12px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .divider {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
