@import '../../../../styles/mixins';

.adminPageHeaderContainer {
  margin-bottom: 40px;

  .headerInner {
    display: flex;
    flex-direction: row;
    align-items: center;

    .pageHeader {
      flex-grow: 1;

      .headerTitle {
        margin: 0 0 8px;
        font-weight: 700;
        line-height: 1.5;
        font-size: 1.25rem;

        @include bp (m) {
          font-size: 1.5rem;
        }
      }
    }

    .pageButtons {
      flex-shrink: 0;
      gap: 8px;
      display: flex;
      flex-direction: row;
    }
  }
}