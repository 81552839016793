@import '../../../../styles/mixins';

.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 24px;
  height: 100%;
  border-radius: 16px;
  border: dashed 1px;

  img {
    width: 100%;
    max-width: 160px;
  }

  .description {
    margin: 8px 0 0;
    font-family: inherit;
    font-weight: 600;
    font-size: 1.0625rem;
    line-height: 1.55556;
    text-align: center;

    @include bp(s) {
      font-size: 1.125rem;
    }
  }
}