@import '../../../../styles/mixins';

.detailsContainer {
  background-image: none;
  position: relative;
  box-shadow: rgba(145, 158, 171, 0.2) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
  z-index: 0;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  border-radius: 16px;

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 0;

    .title {
      margin: 0;
      font-family: inherit;
      font-weight: 600;
      font-size: 1.0625rem;
      line-height: 1.55556;
      display: block;

      @include bp(s) {
        font-size: 1.125rem;
      }
    }
  }

  .content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: inherit;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.57143;
    overflow: auto;
  }
}
