@import '../../../../styles/mixins';

.listFormContainer {
  display: flex;

  .titleContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    padding: 24px 24px 0;
    margin-bottom: 24px;

    @include bp(l) {
      width: 33%;
      padding: 0;
      margin: 0;
    }

    .subtitle {
      font-size: 14px;
    }
  }

  .formContainerOutside {
    width: 66%;
    flex-grow: 0;
    flex-basis: auto;
    min-width: 0;
    box-sizing: border-box;
    padding-bottom: 12px;
    padding-top: 12px;

    &.submitContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
    }

    &.fullWidthContainerOutside {
      width: 100%;
    }

    @include bp(l) {
      padding: 12px;
    }

    .formContainer {
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-image: none;
      overflow: hidden;
      position: relative;
      box-shadow: rgba(145, 158, 171, 0.2) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
      border-radius: 16px;
      z-index: 0;

      .formInner {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px;

        .formInputsRowContainer {
          display: flex;
          flex-direction: column;
          gap: 24px;

          @include bp(s) {
            gap: 16px;
          }

          .formInputsRow {
            display: flex;
            flex-direction: column;
            gap: 24px 16px;
            width: 100%;
            justify-content: center;

            @include bp(s) {
              flex-direction: row;
            }

            > div {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
