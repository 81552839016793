@import '../../../../../styles/mixins';

.kanbanColumn {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-width: 1px;
  position: relative;
  border-style: solid;
  padding: 20px 16px 16px 16px;
  border-radius: 16px;
  gap: 20px;
  width: 280px;

  .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .cardsCount {
      height: 24px;
      min-width: 24px;
      line-height: 0;
      cursor: default;
      align-items: center;
      display: inline-flex;
      justify-content: center;
      font-size: 0.75rem;
      font-weight: 700;
      white-space: nowrap;
      padding: 0 6px;
      transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
      border-radius: 50%;
    }

    .title {
      font-family: inherit;
      font-weight: 600;
      padding-top: 6px;
      padding-bottom: 6px;
      font-size: 1.0625rem;
      line-height: 1.55556;
      border-radius: 8px;
      border-width: 2px;
      border-style: solid;
      border-color: transparent;
      transition: padding-left 300ms cubic-bezier(0.4, 0, 0.2, 1), border-color 300ms cubic-bezier(0.4, 0, 0.2, 1);
      position: relative;
      display: inline-flex;
      align-items: center;
      margin-left: 8px;
      margin-right: 8px;

      @include bp(s) {
        font-size: 1.125rem;
      }
    }
  }

  .cardsContainer {
    //margin: 0;
    //padding: 0;
    //list-style-type: none;
    //min-height: 80px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    //scrollbar-width: none;
    //overflow-y: auto;
    //flex: 1 1 auto;
  }
}