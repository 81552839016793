.kanbanCardOuterContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  touch-action: manipulation;

  .kanbanCardInnerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: grab;
    position: relative;
    transform-origin: 50% 50%;
    touch-action: manipulation;
    box-shadow: rgba(145, 158, 171, 0.16) 0 1px 2px 0;
    outline: none;
    overflow: hidden;
    border-radius: 12px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);

    .content {
      padding: 24px 16px 16px;
      position: relative;

      .iconsContainer {
        flex-shrink: 0;
        display: inline-flex;
        top: 4px;
        right: 4px;
        position: absolute;
        gap: 2px;

        .icon > svg {
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          display: inline-flex;
        }
      }

      .title {
        margin: 0 0 16px;
        font-family: inherit;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.57143;
      }

      .cardFooterContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        .icons {
          display: flex;
          flex-direction: row;
          flex-grow: 1;
          align-items: center;
          font-family: inherit;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1.5;
          gap: 8px;
        }
      }
    }
  }
}