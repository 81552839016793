.listHierarchyContentContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;

  :global(.dnd-sortable-tree_simple_tree-item) {
    border-radius: 8px;
    color: inherit;
    gap: 4px;
    padding: 14px 12px;
    box-shadow: rgba(145, 158, 171, 0.6) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
    border: transparent;
    border-color: inherit;
  }

  :global(.dnd-sortable-tree_simple_tree-item-collapse_button) {
    margin-left: -8px;
  }
}
