.phoneInputContainer {
  position: relative;
  display: flex;

  > div {
    width: 100%;

    input {
      padding: 18px 0 14.5px 72px;
    }
  }

  .inputButton {
    align-items: center;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 16px 0 0;
    border-radius: 0;
    padding: 0 0 0 12px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    color: inherit;
    z-index: 9;
    display: flex;
    position: absolute;
    justify-content: flex-start;
    width: 60px;
    height: 22px;

    .selectedFlag {
      width: 22px;
      height: 22px;
      flex-shrink: 0;
      overflow: hidden;
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 22px;
    }

    .dropdownIcon {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      display: inline-flex;
      margin-left: 4px;
    }

    .verticalDivider {
      height: 20px;
      margin-left: auto;
      width: 1px;
    }
  }

  .inputContainer {
    :global(.MuiInputBase-adornedEnd) {
      padding-right: 0;
    }

    .endAdornment {
      padding-right: 6px;
    }
  }
}

.countriesPopover {
  width: 100%;
  max-width: 320px;
  height: 320px;
  max-height: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  outline: 0;
  padding: 4px;
  border-radius: 10px;

  .searchContainer {
    padding: 12px 8px;

    .searchInput {
      width: 100%;
    }
  }

  .countriesList {
    flex: 1 1 auto;
    overflow-x: hidden;

    .listElement {
      padding: 0;
      list-style: none;
      margin: 0;
      position: relative;

      .itemContainer {
        outline: 0;
        border: 0;
        background-color: transparent;
        margin: 0;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        color: inherit;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        text-decoration: none;
        box-sizing: border-box;
        white-space: nowrap;
        font-weight: 400;
        font-family: inherit;
        font-size: 0.875rem;
        line-height: 1.57143;
        padding: 6px 8px;
        border-radius: 6px;
        min-height: auto;
        gap: 4px;

        &:hover {
          text-decoration: none;
          background-color: rgba(145, 158, 171, 0.08);
        }

        &:not(:last-of-type) {
          margin-bottom: 4px;
        }

        .itemFlag {
          width: 22px;
          height: 22px;
          flex-shrink: 0;
          overflow: hidden;
          font-size: 22px;
          align-items: center;
          display: inline-flex;
          justify-content: center;
          margin-right: 8px;
        }

        .countryNameContainer {
          flex: 1 1 auto;
          min-width: 0;
          margin: 0;

          .countryName {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            font-family: inherit;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.57143;
          }

          .countryValue {
            margin: 0;
            display: block;
            font-family: inherit;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.5;
          }
        }
      }
    }
  }
}