@import '../../../../styles/mixins';

.valueContainer {
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: rgba(145, 158, 171, 0.2) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);

  .valueLabel {
    font-family: inherit;
    font-weight: 400;
    font-size: 0.85rem;
  }
}