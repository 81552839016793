.textDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;

  .label {
    width: 120px;
    flex-shrink: 0;
  }

  .contentContainer {
    padding: 0;

    img {
      cursor: pointer;
    }
  }
}