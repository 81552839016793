@import '../../../styles/mixins';

.drawerContainer {
  overflow: hidden;
  display: flex;
  min-height: 38px;
  flex-shrink: 0;
  align-items: center;
  padding-right: 14px;

  .scroller {
    overflow: auto hidden !important;
    margin-bottom: 0;
    position: relative;
    display: inline-block;
    flex: 1 1 auto;
    white-space: nowrap;
    width: 100%;
    padding: 8px;
    background-color: transparent;
  }

  .indicator {
    padding-top: 8px;
    padding-bottom: 8px;
    height: 100%;
    background-color: transparent;
    cursor: pointer;

    > span {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      box-shadow: rgba(145, 158, 171, 0.16) 0 1px 2px 0;
      display: block;
    }
  }

  .tab {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    appearance: none;
    text-decoration: none;
    font-family: inherit;
    font-size: 0.875rem;
    text-transform: unset;
    max-width: none;
    position: relative;
    flex: 1 1 0;
    overflow: hidden;
    white-space: normal;
    text-align: center;
    flex-direction: row;
    opacity: 1;
    min-width: 48px;
    min-height: auto;
    padding: 8px 0;
    font-weight: 500;
    line-height: 1.57143;
    border-radius: 0;
    z-index: 1;

    &.isActive {
      font-weight: 600;
    }
  }
}

.contentWrapper {
  padding: 24px 20px !important;
}