@import '../../../styles/mixins';

.pdfPreview {
  width: 100%;

  canvas {
    margin-left: auto;
    margin-right: auto;
  }

  .page {
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-left: 2px solid;
    border-right: 2px solid;
    margin-bottom: 18px;
  }
}

.loader {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}