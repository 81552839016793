@import '../../../../../styles/mixins';

.statusTableCellContainer {
  height: 24px;
  min-width: 24px;
  line-height: 0;
  cursor: default;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 700;
  white-space: nowrap;
  padding: 0 6px;
  border-radius: 6px;
  font-family: inherit;

  &.clickable {
    cursor: pointer;
  }

  &.popoverAction {
    min-height: 24px !important;
    cursor: pointer;

    @include bp(l) {
      min-height: 28px !important;
    }

    &:not(:last-of-type) {
      margin-bottom: 4px;
    }

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      cursor: auto;
      opacity: 0.5;
    }

    &.selected {
      opacity: 1;
    }
  }
}

.popover {
  width: auto;
}
