@import '../../../styles/mixins';

.listDashboardContainer {
  display: flex;
  gap: 24px 24px;
  box-sizing: border-box;
  min-width: 0;
  flex-flow: wrap;

  .widgetContainer {
    flex-grow: 0;
    flex-basis: auto;

    &.full {
      width: 100%;

      @include bp(m) {
        width: 100%;
      }
    }

    &.half {
      width: 100%;

      @include bp(m) {
        width: calc(50% - 8px);
      }
    }

    &.twoThirds {
      width: 100%;

      @include bp(m) {
        width: calc(66% - 2px);
      }
    }

    &.oneThird {
      width: 100%;

      @include bp(m) {
        width: calc(33% - 12px);
      }
    }
  }
}