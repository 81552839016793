.countryFlagContainer {
  color: inherit;

  .countryFlag {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    overflow: hidden;
    display: inline-flex;
    margin-left: 4px;
    margin-right: -4px;
    margin-top: -2px;
    font-size: 22px;
    text-align: center;
    justify-content: center;
    align-content: center;
  }
}
