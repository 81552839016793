.hierarchicalItem {
  cursor: grab;
  font-size: 14px;
  font-family: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;

  .avatar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    font-size: 1rem;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    user-select: none;
  }
}
