.commentOuterContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .commentContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .avatar {
      position: relative;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      flex-shrink: 0;
      font-size: 1.25rem;
      line-height: 1;
      border-radius: 50%;
      overflow: hidden;
      user-select: none;
      width: 40px;
      height: 40px;
      border: 2px solid;

      img {
        width: 100%;
        height: 100%;
        text-align: center;
        object-fit: cover;
        color: transparent;
        text-indent: 10000px;
      }
    }

    .contentContainer {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex-grow: 1;

      .nameContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .name {
          margin: 0;
          font-family: inherit;
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.57143;
        }

        .actionsContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          .date {
            margin: 0;
            font-family: inherit;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.5;
          }
        }
      }

      .content {
        margin: 0;
        font-family: inherit;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.57143;
      }
    }
  }

}