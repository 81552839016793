@import '../../../styles/mixins';

.totalStatisticsWidgetContainer {
  background-image: none;
  position: relative;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12);
  display: flex;
  align-items: center;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 16px;
  padding: 24px;
  overflow: unset;
  z-index: unset;

  .mainStatisticContainer {
    flex-grow: 1;

    .title {
      font-family: inherit;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.57143;
    }

    .value {
      font-family: inherit;
      margin-top: 12px;
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.5;

      @include bp(s) {
        font-size: 1.625rem;
      }

      @include bp(m) {
        font-size: 1.875rem;
      }

      @include bp(l) {
        font-size: 2rem;
      }
    }

    .changeContainer {
      display: flex;
      align-items: center;
      gap: 4px;

      .change {
        font-family: inherit;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.57143;
      }

      .changePeriod {
        font-family: inherit;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.57143;
      }
    }
  }

  .chartContainer {
    flex-shrink: 0;
    position: relative;
    border-radius: 12px;
    width: 100px;
    height: 90px;
  }
}