.dateTimeTableCell {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .time {
    font-family: inherit;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.5;
  }
}