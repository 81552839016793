.tableHeadBulkActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  height: 58px;
  position: absolute;

  &.isOutlined {
    top: 16px;
    border-radius: 16px;
  }

  .selectedCount {
    margin: 0 0 0 16px;
    font-family: inherit;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.57143;
    flex-grow: 1;
  }

  .actionsContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    .action {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      font-family: inherit;
      text-align: center;
      font-size: 1.5rem;
      outline: 0;
      border-width: 0;
      margin: 0;
      text-decoration: none;
      flex: 0 0 auto;
      padding: 5px;
      border-radius: 50%;
      overflow: visible;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}
