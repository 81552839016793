.breadcrumbItem {
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }

  &.disabled {
    text-decoration: none;
  }
}
