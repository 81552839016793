.statisticsItemContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

  .iconContainer {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    width: 56px;

    .progress {
      width: 56px !important;
      height: 56px !important;
      opacity: 0.3;
      position: absolute;
    }
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      margin: 0;
      font-family: inherit;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5;
    }

    .subtitle {
      font-family: inherit;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.57143;
    }

    .value {
      margin: 0;
      font-family: inherit;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.57143;
    }
  }
}
