.richTextInputContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  .label {
    font-family: inherit;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.57143;
    margin: 0 0 12px;
  }

  .richTextEditorContainer {
    position: relative;

    .richTextEditor {
      height: 300px;
      display: flex;
      flex-direction: column;

      :global(.ql-toolbar) {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-color: inherit;
      }

      :global(.ql-container) {
        height: 100%;
        min-height: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-color: inherit;
      }
    }

    .loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    }
  }
}
