@import '../../../styles/mixins';

.tableSelectInputContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  .label {
    font-family: inherit;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.57143;
  }

  .filtersContainer {
    margin-top: 12px;
    display: flex;
    width: 100%;
    gap: 16px;
    flex-direction: column;

    @include bp(s) {
      flex-direction: row;
    }
  }

  .searchInput {
    margin-top: 16px;
  }

  .valuesContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
  }

  .divider {
    margin-top: 16px;
  }

  .fullTable {
    margin-top: 24px;
  }
}
