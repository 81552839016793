.longTextDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;

  .label {
    width: 120px;
    flex-shrink: 0;
  }

  .more {
    margin-top: 4px;
    display: block;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }

  .content {
    white-space: pre-wrap;
  }
}