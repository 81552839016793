@import '../../../../styles/mixins';

.dynamicInputLine {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  box-shadow: rgba(145, 158, 171, 0.2) 0 0 2px 0,
  rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
  border-radius: 16px;
  padding: 16px;

  .inputs {
    display: flex;
    gap: 16px;
    width: 100%;

    &.row {
      flex-direction: column;

      @include bp(s) {
        flex-direction: row;
      }
    }

    &.column {
      flex-direction: column;
    }

    .input {
      width: 100%;
    }
  }

  .closeIcon {
    position: absolute;
    top: -4px;
    right: -4px;
    z-index: 100;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    font-family: inherit;
    text-align: center;
    font-size: 1.125rem;
    outline: 0;
    border: none;
    margin: 0;
    text-decoration: none;
    flex: 0 0 auto;
    border-radius: 50%;
    overflow: visible;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    padding: 2.8px;

    &:hover {
      opacity: 0.8;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }
}
