.childrenEditPopover {
  :global(.MuiPopover-paper) {
    max-width: 320px;
    width: 100%;
  }

  .childrenEditPopoverContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 16px;
    width: 100%;

    .innerBox {
      display: flex;
      flex-direction: column;
      gap: 4px;
      border-bottom: 1px solid;
      padding-bottom: 6px;
      width: 100%;

      .label {
        font-size: 12px;
      }
    }
  }
}
