@import '../../../styles/mixins';

.listTabsContainer {
  margin-bottom: 24px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  min-height: 48px;
  display: flex;

  @include bp(m) {
    margin-bottom: 40px;
  }

  .tab {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: "Public Sans", sans-serif;
    font-size: 0.875rem;
    text-transform: unset;
    max-width: 360px;
    position: relative;
    flex-shrink: 0;
    overflow: hidden;
    white-space: normal;
    text-align: center;
    flex-direction: row;
    min-width: 48px;
    min-height: 48px;
    font-weight: 600;
    line-height: 1.57143;
    padding: 8px 0;

    &:not(:last-child) {
      margin-right: 40px;
    }

    .label {
      margin-left: 8px;
    }

    :global(.MuiTab-iconWrapper) {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      display: inline-flex;
    }
  }
}
