.tab {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  line-height: 1.25;
  font-size: 0.875rem;
  text-transform: unset;
  font-family: "Public Sans", sans-serif;
  max-width: 360px;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  flex-direction: row;
  padding: 0;
  min-width: 48px;
  min-height: 48px;
  font-weight: 500;
  opacity: 1;

  &:not(:last-child) {
    margin-right: 40px;
  }

  &.isActive {
    font-weight: 600;
  }

  .label {
    margin-left: 8px;
  }
}
