@import '../../../../../../styles/mixins';

.languagesTabs {
  margin-bottom: 10px;
}

.languageTab {
  .languageLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .flag {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}