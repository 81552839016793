@import '../../../styles/mixins';

.totalWidgetContainer {
  position: relative;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 16px;
  overflow: hidden;
  z-index: 0;

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .headerContainer {
    display: flex;
    align-items: center;
    padding: 24px 24px 0;

    .headerInner {
      flex: 1 1 auto;

      .title {
        font-family: inherit;
        margin: 0;
        font-weight: 600;
        font-size: 1.0625rem;
        line-height: 1.55556;

        @include bp(s) {
          font-size: 1.125rem;
        }
      }

      .subtitle {
        margin: 4px 0 0;
        font-family: inherit;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.57143;
      }
    }
  }

  .chartContainer {
    position: relative;
    border-radius: 12px;
    margin: 48px auto;
    width: 240px;
    height: 240px;

    @include bp(1536px) {
      width: 260px;
      height: 260px;
    }
  }

  .legendContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 24px;
    justify-content: center;

    .legendItemContainer {
      display: inline-flex;
      flex-direction: column;

      .legendInner {
        display: inline-flex;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.8125rem;
        font-weight: 500;

        .legendColor {
          width: 12px;
          height: 12px;
          flex-shrink: 0;
          display: flex;
          border-radius: 50%;
          position: relative;
          align-items: center;
          justify-content: center;
        }

        .legendLabel {
          flex-shrink: 0;
        }
      }
    }
  }
}