@import '../../../../styles/mixins';

.tab {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  appearance: none;
  text-decoration: none;
  font-family: inherit;
  font-size: 0.875rem;
  text-transform: unset;
  max-width: none;
  position: relative;
  flex: 1 1 0;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  flex-direction: row;
  opacity: 1;
  min-width: 48px;
  min-height: auto;
  padding: 8px 0;
  font-weight: 500;
  line-height: 1.57143;
  border-radius: 0;
  z-index: 1;

  &.isActive {
    font-weight: 600;
  }
}