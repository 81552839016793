.kanbanContainer {
  display: flex;
  flex-direction: row;
  gap: 24px;
  min-height: 0;
  flex: 1 1 auto;
  overflow-x: auto;

  .skeleton {
    transform: none;
  }
}