.attachmentInputContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  .label {
    font-family: inherit;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.57143;
    margin: 0 0 12px;
  }

  .attachmentInputContainerOuter {
    display: flex;
    flex-flow: wrap;
    gap: 8px;

    .fileSelectContainer {
      display: inline-flex;
      width: auto;
      gap: 8px;
      flex-wrap: wrap;

      .fileUpload {
        width: 64px;
        height: 64px;
        flex-shrink: 0;
        display: flex;
        border-radius: 8px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        border: dashed 1px;

        &:hover {
          opacity: 0.72;
        }

        input {
          display: none;
        }
      }
    }
  }
}
