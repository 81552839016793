@import '../../../styles/mixins';

.adminPageWrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  display: block;
  max-width: 1200px;

  @include bp(s) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include bp(l) {
    padding-left: 24px;
    padding-right: 24px;
  }

  &.fullWidth {
    max-width: none;
  }
}
