@import '../../../../styles/mixins';

.tableSelectInputFilterButton {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  font-weight: 600;
  font-family: inherit;
  flex-direction: column;
  outline: 0;
  margin: 0;
  text-decoration: none;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  padding: 10px;
  font-size: 0.75rem;
  line-height: 1.5;

  svg {
    flex-shrink: 0;
    display: inline-flex;
    margin-bottom: 2px;
    width: 20px;
    height: 20px;
  }
}

.popoverContainer {
  width: 300px;

  .popoverArrow {
    width: 14px;
    height: 14px;
    position: absolute;
    border-bottom-left-radius: 3.5px;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    border: 1px solid;
    backdrop-filter: blur(6px);
    top: -6.5px;
    transform: rotate(135deg);
    right: 44px;
  }

  .popoverSearchContainer {
    padding: 16px 16px 12px;

    .inputContainer {
      width: 100%;
    }
  }

  .divider {
    border-width: 0 0 thin;
    border-style: dashed;
  }

  .popoverContent {
    padding: 16px 16px 12px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-height: 300px;
  }
}
