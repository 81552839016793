.table {
  position: relative;

  &.isOutlined {
    border-collapse: separate;
    border-spacing: 0 16px;

    :before {
      content: none;
    }

    :after {
      content: none;
    }
  }
}