.linkTableCell {
  margin: 0;
  font: inherit;
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
