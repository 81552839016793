@import '../../../../styles/mixins';

.listTableFilters {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  gap: 16px;
  align-items: flex-end;

  @include bp(s) {
    padding: 20px;
  }

  @include bp(m) {
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
  }

  &.isOutlined {
    padding: 6px 0 0;
    padding-bottom: 6px;
  }

  .controlContainer {
    width: 100%;

    @include bp(m) {
      width: 180px;

      &:last-of-type {
        width: auto;
        flex: 1;
      }
    }
  }
}
