@import '../../../styles/mixins';

.actionModal {
  display: flex;

  @include bp (m) {
    width: 33% !important;
  }

  @include bp (l) {
    width: 50% !important;
  }

  > button {
    width: 100%;
  }
}