@import '../../../styles/mixins';

.timeIntervalContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid;
  padding-bottom: 32px;

  &:last-child {
    border: none;
  }

  .timeInterval {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include bp(s) {
      flex-direction: row;
      align-items: center;
    }

    .label {
      font-family: inherit;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.57143;
      margin-bottom: 12px;

      @include bp(s) {
        width: 120px;
        margin-bottom: 0;
      }
    }

    .inputs {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 16px;

      @include bp(s) {
        width: calc(100% - 120px);
      }

      > div {
        width: 50%;
      }
    }
  }
}
