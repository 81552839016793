.fileDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;

  .label {
    width: 120px;
    flex-shrink: 0;
  }

  .files {
    display: inline-flex;
    width: auto;
    gap: 8px;
    flex-wrap: wrap;
  }
}
