.userDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  .avatar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    user-select: none;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.57143;
    flex: 1 1 auto;
    align-items: flex-start;
    gap: 4px;

    .title {
      margin: 0;
      font: inherit;
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }

    .subTitle {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.57143;
    }
  }
}
