@import '../../../styles/mixins';

.welcomeWidgetContainer {
  background-image: linear-gradient(to right, rgba(20, 26, 33, 0.88) 0%, #141A21 75%), url('../../../../assets/background-1.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  gap: 40px;
  border-radius: 16px;
  display: flex;
  position: relative;
  align-items: center;
  border: solid 1px;
  padding: 40px 24px;
  text-align: center;
  flex-direction: column;

  @include bp(m) {
    height: 100%;
    padding-left: 40px;
    text-align: left;
    flex-direction: row;
  }

  .textPart {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: center;

    @include bp(m) {
      align-items: flex-start;
    }

    .title {
      font-family: inherit;
      margin: 0 0 8px;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.5;
      white-space: pre-line;

      @include bp(m) {
        font-size: 1.5rem;
      }
    }

    .subtitle {
      font-family: inherit;
      margin: 0 0 24px;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.57143;
      opacity: 0.64;
      max-width: 360px;
    }
  }

  .imagePart {
    max-width: 260px;
    max-height: 240px;

    img {
      user-select: none;
      pointer-events: none;
      display: inline-block;
      max-width: 260px;
      max-height: 240px;
      flex-shrink: 0;
      height: auto;
      transition: fill 300ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}