@import '../../../../../styles/mixins';

.itemContainer {
  margin-bottom: 6px;
  border-radius: 8px;
  padding: 10px 14px;
  font-family: inherit;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.57143;
  cursor: pointer;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  box-shadow: rgba(145, 158, 171, 0.2) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;

  .avatar {
    height: 24px;
    width: 24px;
  }
}