@import '../../../../styles/mixins';

.detailsWrapperContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include bp(m) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .listDetailsColumn {
    display: flex;
    flex-direction: column;
    gap: 24px;
    box-sizing: border-box;

    @include bp(m) {
      width: calc(100% * 8 / 12 - 12px);

      &:nth-child(even) {
        width: calc(100% * 4 / 12 - 12px);
      }
    }
  }
}