@import '../../../styles/mixins';

.listStatisticsContainer {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  position: relative;
  box-shadow: rgba(145, 158, 171, 0.2) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
  border-radius: 16px;
  z-index: 0;
  margin-bottom: 24px;

  @include bp(m) {
    margin-bottom: 40px;
  }

  .items {
    display: flex;
    flex-direction: row;
    padding: 16px 10px;
    gap: 20px;
    overflow: auto;
  }
}
