.longTextTableCellContainer {
  .more {
    margin-top: 4px;
    display: block;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }

  .content {
    white-space: pre-wrap;
  }
}