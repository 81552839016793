.labelContainer {
  height: 24px;
  min-width: 24px;
  line-height: 0;
  border-radius: 6px;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  text-transform: capitalize;
  padding: 0 6px;
  font-size: 0.75rem;
  font-weight: 700;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}