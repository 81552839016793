@import '../../../styles/mixins';

.fileUploadInputContainer {
  position: relative;
  width: 100%;

  .fileDropContainer {
    position: relative;
    cursor: pointer;
    padding: 40px;
    outline: none;
    border-radius: 8px;
    overflow: hidden;
    border: 1px dashed;
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1), padding 300ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      opacity: 0.72;
    }

    input {
      display: none;
    }

    .placeholderContainer {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .image {
        width: 200px;
      }

      .placeholder {
        display: flex;
        text-align: center;
        gap: 8px;
        flex-direction: column;

        .title {
          font-family: inherit;
          font-weight: 600;
          font-size: 1.0625rem;
          line-height: 1.55556;

          @include bp(s) {
            font-size: 1.125rem;
          }
        }

        .subtitle {
          font-family: inherit;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.57143;

          span {
            text-decoration: underline;
            margin: 0 4px;
            color: inherit;
          }
        }

        .acceptedFiles {
          font-family: inherit;
          font-weight: 400;
          font-size: 0.75rem;
        }
      }
    }
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .uploadedFiles {
    display: flex;
    gap: 8px;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}