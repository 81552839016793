@import '../../../styles/mixins';

.statusChangerButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  font-family: inherit;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.71429;
  text-transform: unset;
  min-width: 64px;
  color: inherit;
  box-shadow: none;
  height: 30px;
  outline: 0;
  border-width: 0;
  margin: 0;
  text-decoration: none;
  padding: 6px 8px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  border-color: inherit;

  .icon {
    margin-right: -2px;
    margin-left: 4px;
    font-size: 18px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    display: inline-flex;
  }
}

.popoverArrow {
  width: 14px;
  height: 14px;
  position: absolute;
  border-bottom-left-radius: 3.5px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  border: 1px solid;
  backdrop-filter: blur(6px);
  top: -6.5px;
  transform: rotate(135deg);
  right: 44px;
}

.statusItem {
  min-height: 32px !important;

  @include bp(l) {
    min-height: 40px !important;
  }

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
}
