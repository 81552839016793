.tableRow {
  transition: background-color 0.5s ease-out;

  &.isHighlighted {
    transition: background-color 0.5s ease-in-out;
  }

  &.isExpanded {
    > td {
      border-bottom: none;
    }
  }

  &.isOutlined {
    > td {
      border-bottom: 1px solid;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.expandedRowContent {
    .mainCell {
      padding-left: 40px;
      width: calc(100% - 40px);
    }
  }
}