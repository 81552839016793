.kanbanCardUsers {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  .kanbanCardUser {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-family: inherit;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    user-select: none;
    border: 2px solid;
    box-sizing: border-box;
    margin-left: -8px;
    font-size: 16px;
    font-weight: 600;
    width: 24px;
    height: 24px;
  }
}
