.listTabsContainer {
  overflow: hidden;
  min-height: 48px;
  display: flex;
  box-shadow: rgba(145, 158, 171, 0.08) 0px -2px 0px 0px inset;

  .scroller {
    overflow: auto hidden !important;
  }
}
