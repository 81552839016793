@import '../../../../../styles/mixins';

.listKanbanFilters {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  gap: 16px;
  align-items: flex-end;
  box-shadow: rgba(145, 158, 171, 0.2) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
  border-radius: 16px;
  margin-bottom: 20px;

  @include bp(s) {
    padding: 20px;
  }

  @include bp(m) {
    -webkit-box-align: center;
    align-items: flex-start;
    flex-direction: row;
  }

  .controlContainer {
    width: 100%;

    @include bp(m) {
      width: 180px;

      &:last-child {
        width: auto;
        flex: 1;
      }
    }
  }
}
