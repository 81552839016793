.tableCell {
  &.right {
    flex-direction: row-reverse;
    text-align: right;
  }

  &.isOutlined {
    border-top: 1px solid;
    border-bottom: 1px solid;

    &:first-child {
      border-left: 1px solid;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }

    &:last-child {
      border-right: 1px solid;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}