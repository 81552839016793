.switcherInputContainer {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 0;
  margin-right: 0;
  width: 100%;

  :global(.MuiSwitch-switchBase) {
    top: 6px;
  }

  .label {
    margin: 0 0 4px;
    font-family: inherit;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.57143;
  }

  .labelExplanation {
    margin: 0;
    font-family: inherit;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.57143;
  }
}