.avatarInputContainerOuter {
  padding-top: 40px;
  padding-bottom: 40px;

  .avatarInputContainer {
    padding: 8px;
    margin: auto;
    width: 144px;
    height: 144px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 50%;
    border: 1px dashed;
    position: relative;

    input {
      display: none;
    }

    .loader {
      position: absolute;
      left: 0;
      top: 0;
      width: 144px;
      height: 144px;
      cursor: pointer;
      overflow: hidden;
      border-radius: 50%;
      border-right: 4px solid;
      animation: spin 1s infinite linear;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .avatarInput {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 50%;

      .avatarInputInner {
        overflow: hidden;
        position: relative;
        vertical-align: bottom;
        display: inline-block;
        width: 100%;
        height: 100%;

        .imageContainer {
          color: transparent;
          filter: blur(0);
          transition: filter .3s;
          width: 100%;
          height: 100%;
          vertical-align: bottom;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            opacity: 1;
            transition: opacity .3s;
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: bottom;
            max-width: 100%;
          }

          svg {
            height: 100%;
            width: 100%;
          }
        }
      }

      .placeholderContainer {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        display: flex;
        position: absolute;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        border-radius: 50%;
        transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
        opacity: 0;

        &:hover {
          opacity: 0.72;
        }

        .photoIcon {
          width: 32px;
          height: 32px;
          flex-shrink: 0;
          display: inline-flex;
        }

        .updatePhoto {
          margin: 0;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1.5;
        }
      }
    }
  }

  .allowedFiles {
    margin: 24px auto 0;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.5;
    text-align: center;
  }
}
