.breadcrumbs {
  margin: 0;

  .breadcrumbSeparator {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-left: 8px;
    margin-right: 8px;
  }
}
