@import '../../styles/mixins';

.timeScheduleContainer {
  display: flex;
  flex-direction: column;

  .timeSchedule {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .buttonSubmit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }
}
